label, input, button {
    margin: 10px;
    padding: 5px;
}

footer {
    bottom: 0;
    position: absolute;
    width: 90%;
    height: 40px;
}